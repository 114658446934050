import { useContext } from "react";
import "./Footer.scss";
import { StoreContext } from "../../store/store";
import { Link } from "react-router-dom";

export const Footer = () => {
  const { menusData, lang } = useContext(StoreContext);
  const { footer: footerData = {} } = menusData ?? {};
  const { info = {}, menuFooterTwo: menu = [] } = footerData;
  const {
    copyright = {},
    facebook = {},
    instagram = {},
    linkedin = {},
    twitter = {},
    youtube = {},
    tiktok = {},
    logo_mediawan = {},
  } = info;

  const menuFormated = menu.map((item) => {
    const { slug, title } = item[lang];

    return {
      slug,
      title,
      externalLink: item.externalLink,
      url: item.url,
      id: item.id,
      idPage: item.idPage,
    };
  });

  return (
    footerData && (
      <footer className="footer-component">
        <div className="footer-left">
          <Link to={`/${lang}/`}>
            <img
              src={logo_mediawan.data?.path}
              alt={logo_mediawan.data?.alt}
              className="footer-left-logo"
            />
          </Link>
          <div className="footer-left-copyright">
            <span className="footer-left-copyright-text">{copyright.data}</span>
          </div>
        </div>

        <div className="footer-center">
          <Link to={twitter.data} target="_blank">
            <img
              src="/newIcon/x.svg"
              alt="twitter icon"
              className="footer-center-icon"
            />
          </Link>
          <Link to={facebook.data} target="_blank">
            <img
              src="/newIcon/facebook.svg"
              alt="facebook icon"
              className="footer-center-icon"
            />
          </Link>
          <Link to={instagram.data} target="_blank">
            <img
              src="/newIcon/instagram.svg"
              alt="instagram icon"
              className="footer-center-icon"
            />
          </Link>
          <Link to={youtube.data} target="_blank">
            <img
              src="/newIcon/youtube.svg"
              alt="youtube icon"
              className="footer-center-icon"
            />
          </Link>
          <Link to={linkedin.data} target="_blank">
            <img
              src="/newIcon/linkedin.svg"
              alt="linkedin icon"
              className="footer-center-icon"
            />
          </Link>
          <Link to={tiktok.data} target="_blank">
            <img
              src="/newIcon/tiktok.svg"
              alt="tiktok icon"
              className="footer-center-icon"
            />
          </Link>
        </div>

        <div className="footer-right">
          <ul className="footer-right-list">
            {menuFormated &&
              menuFormated.map((item, index) => (
                <li key={index} className="footer-right-list-item">
                  <Link
                    to={item.url ? `${item.slug}` : `/${lang}/${item.slug}`}
                    target={item.externalLink ? "_blank" : "_self"}
                    className="footer-right-list-item-link"
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
          </ul>
        </div>

        <div className="footer-copyright">
          <span className="footer-copyright-text">{copyright.data}</span>
        </div>
      </footer>
    )
  );
};
